import React, { useState, useRef, useEffect, useCallback } from 'react'
import { Box, Button, Card, FormControl, InputLabel, MenuItem, Select, Typography, Dialog, DialogTitle, DialogContent, TextField, LinearProgress } from '@mui/material';
import styled from '@emotion/styled';
import { useNavigate, useParams } from 'react-router-dom';
import '../../../Styles/newNews.css'
import { getAllAddSet, getNewsBySlugId, newsImageUpload, putNewsBySlug, putPublishNews } from '../../../Services/AdminServices';
import toast, { Toaster } from 'react-hot-toast';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
import parse from 'html-react-parser';

const card = styled(Card)(({ theme }) => ({
    backgroundColor: "#fff",
    boxShadow: 'none',
    marginBottom: 20,
    padding: 20,
    width: '70%',
    '@media (max-width: 1200px)': {
        width: '70%', // Adjust width for desktop
    },

    '@media (max-width: 992px)': {
        width: '100%', // Adjust width for tablets
    },
    '@media (max-width: 768px)': {
        width: '100%',// Adjust width for Mobiles
    },
}));

// Image Resize
Quill.register('modules/imageResize', ImageResize);

// Font size in pixel editor
var Size = Quill.import('attributors/style/size');
Size.whitelist = ['8px', '10px', '12px', '14px', '18px', '24px', '36px'];
Quill.register(Size, true);

function EditNews({ categories }) {

    const { newsId } = useParams();
    const navigate = useNavigate();

    // Refs for error fields for scroll
    const titleRef = useRef(null);
    const categoryRef = useRef(null);

    // Editor ref
    const reactQuillRef = useRef(null);

    const [category, setCategory] = useState("");
    const [categoryError, setcategoryError] = useState(false);
    const [matrimonyType, setMatrimonyype] = useState("bride");
    const [plan, setPlan] = useState(30);
    const [updatedDate, setUpdatedDate] = useState(null);
    const [newsStatus, setNewsStatus] = useState("");

    const [spinner, setSpinner] = useState(false);

    const [allAddSets, setAllAddSets] = useState([]);
    const [allAddSetSelect, setAllAddSetSelect] = useState("");

    const handleAddSetSelectChange = (value) => {
        setAllAddSetSelect(value);
    }

    const [title, setTitle] = useState("");
    const [titleError, setTitleError] = useState(false);
    const [editorContent, setEditorContent] = useState('');
    const [publishNewsDialog, setPublishNewsDialog] = useState(false);

    const handleChange = (value) => {
        setEditorContent(value);
    };

    const categoryChange = (e) => {
        setCategory(e.target.value);
    }

    const matrimonyTypeChange = (e) => {
        setMatrimonyype(e.target.value);
    }

    const planChange = (e) => {
        let value = Number(e.target.value)
        setPlan(value);
    };

    const dateChange = (date) => {
        setUpdatedDate(dayjs(date));
    }

    const publishNewsDialogOpen = () => {
        setPublishNewsDialog(true);
    }
    const publishNewsDialogClose = () => {
        setPublishNewsDialog(false);
    }

    const imageHandler = useCallback(async () => {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();

        input.onchange = async () => {
            if (input.files && input.files[0]) {
                const file = input.files[0];
                const formData = new FormData();
                formData.append('image', file);

                try {
                    setSpinner(true);
                    // Make an HTTP request to send the file to the backend
                    const res = await newsImageUpload(formData);
                    const url = res?.data?.file?.url;

                    if (url) {
                        const quill = reactQuillRef.current.getEditor(); // Access the Quill instance

                        if (quill) {
                            const range = quill.getSelection(); // Get the current selection
                            if (range) {
                                quill.insertEmbed(range.index, "image", url); // Insert the image
                                // Ensure cursor is placed after the image to come next line
                                setTimeout(() => {
                                    quill.setSelection(range.index + 1);
                                    quill.focus();
                                    setSpinner(false);
                                }, 0);
                            }
                        }
                    }
                } catch (error) {
                    setSpinner(false);
                    console.error("Image upload failed:", error);
                }
            }
        };
    }, []);

    const modules = {
        toolbar: {
            container: [
                // [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ 'size': ['8px', '10px', '12px', '14px', '18px', '24px', '36px'] }],
                // [{ font: [] }], // fonts
                // [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                ['bold', 'italic',
                    // 'underline', 'strike'
                ],
                [{ 'align': [] }],
                [
                    // 'link', 
                    'image', 'video'],
                // ['blockquote', 'code-block'],
                [{ 'color': [] }, { 'background': [] }],
                ["undo", "redo"]
                // ['clean'] // Add a button to clear formatting
            ],
            handlers: {
                image: imageHandler,
            },
        },
        imageResize: {
            parchment: Quill.import('parchment'),
            modules: ['Resize', 'DisplaySize']
        }
    };


    const getApiData = async () => {
        try {
            // Fetch data from API
            await getNewsBySlugId(newsId).then((newsData) => {
                if (newsData?.data?.news?.category) {
                    setCategory(newsData?.data?.news?.category);
                    if (newsData?.data?.news?.category === "Matrimony") {
                        setMatrimonyype(newsData?.data?.news?.matrimonytype);
                        setPlan(newsData?.data?.news?.data.planDuration);
                    }
                }
                if (newsData?.data?.news?.date) {
                    setUpdatedDate(dayjs(newsData?.data?.news?.date));
                }
                setEditorContent(newsData?.data?.news?.content);
                setTitle(newsData?.data?.news?.title);
                setNewsStatus(newsData?.data?.news?.status);
                setAllAddSetSelect(newsData?.data?.news?.adSet?._id);
            })

            await getAllAddSet().then((resposne) => {
                setAllAddSets(resposne?.data?.adSets)
            })
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        getApiData();
    }, []);

    const scrollToField = (ref) => {
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    // EditorJs Content Description Validation
    const editorErrorValidate = (content) => {
        let arr = [];
        let status = true;

        // Parse HTML and push nodes to an array
        parse(content, {
            replace: (domNode) => {
                arr.push(domNode);
                return null;
            },
        });
        // Find a text node in the parsed content
        const descriptionNode = arr.find((node) => node?.type === "text");
        // Set status based on the presence of a text node
        status = descriptionNode ? false : true;
        return status;
    };

    const editNews = async () => {
        try {
            let error = false;
            if (title === "") {
                setTitleError(true);
                scrollToField(titleRef);
                error = true;
            } else {
                setTitleError(false);
            }

            if (category === "") {
                setcategoryError(true);
                scrollToField(categoryRef);
                error = true;
            } else {
                setcategoryError(false);
            }

            if (editorErrorValidate(editorContent)) {
                toast.error("Please enter the description.")
                error = true;
            }

            if (error) return;

            let categor = categories.find(f => f?._id === category);
            let data = {
                category: categor?._id,
                categoryName: categor?.categoryName,
                type: categor?.type,
                title: title,
                content: editorContent,
                date: updatedDate,
                adSet: allAddSetSelect,
            }

            if (categor?.categoryName === "Matrimony") {
                data.matrimonytype = matrimonyType;
                data.planDuration = plan;
            }

            await putNewsBySlug(newsId, data).then((res) => {
                toast.success(res?.data?.message);
                setTimeout(() => {
                    navigate('/admin/published-news');
                }, 2000);
            })
        } catch (err) {
            toast.error(err.response.data.message);
        }
    };

    const newsPublish = async (id) => {
        await putPublishNews(id).then((res) => {
            toast.success(res?.data?.message);
            setTimeout(() => {
                navigate('/admin/published-news');
            }, 2000);
        }).catch((err) => { toast.error(err.response.data.message); })
    }

    const checkingMatrimonyStatus = (categoryId) => {
        let status = false;
        let category = categories && categories.find(cat => cat?._id === categoryId);
        if (category) {
            if (category?.categoryName === "Matrimony") {
                status = true;
            }
        }
        return status;
    }

    return (
        <div>
            <Box>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
                    Edit News
                </Typography>
            </Box>
            <Box component={card}>
                <Box className="section-new-category-add-news-category-flex" sx={{ fontWeight: 'bold' }}>
                    <div className='new-title-add-information-icon'></div>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Title
                    </Typography>
                </Box>
                <TextField
                    ref={titleRef}
                    error={titleError}
                    id="outlined-basic"
                    label="Title"
                    placeholder='Title'
                    variant="outlined"
                    sx={{ mb: 5 }}
                    fullWidth={true}
                    required={true}
                    value={title}
                    onChange={(e) => { setTitle(e.target.value) }} />

                <Box className="section-new-category-add-news-category-flex" sx={{ fontWeight: 'bold' }}>
                    <div className='new-category-add-information-icon'></div>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Edit Category
                    </Typography>
                </Box>
                <FormControl fullWidth sx={{ mb: 5 }} required={true} error={categoryError} ref={categoryRef}>
                    <InputLabel id="demo-simple-select-label">Category</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={category}
                        label="Category"
                        onChange={(e) => { categoryChange(e) }}
                    >
                        {categories && categories.map((cat, index) => (
                            <MenuItem value={cat?._id} key={index}>{cat?.categoryName}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {checkingMatrimonyStatus(category) && (
                    <Box className="section-new-category-add-news-category-flex">
                        <div className='new-category-add-information-icon'></div>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                            Edit Matrimony Type
                        </Typography>
                    </Box>
                )}
                {checkingMatrimonyStatus(category) && (
                    <FormControl fullWidth sx={{ mb: 5 }} required={true}>
                        <InputLabel id="demo-simple-select-label">Matrimony Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={matrimonyType}
                            label="Matrimony Type"
                            onChange={(e) => { matrimonyTypeChange(e) }}
                        >
                            <MenuItem value={"bride"}>Bride</MenuItem>
                            <MenuItem value={"groom"}>Groom</MenuItem>
                        </Select>
                    </FormControl>
                )}

                {checkingMatrimonyStatus(category) && (
                    <Box className="section-new-category-add-news-category-flex">
                        <div className='new-category-add-information-icon'></div>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                            Edit Matrimony Plan
                        </Typography>
                    </Box>
                )}

                {checkingMatrimonyStatus(category) && (
                    <FormControl fullWidth sx={{ mb: 5 }} required={true}>
                        <InputLabel id="plan-select-label">Matrimony Plan</InputLabel>
                        <Select
                            labelId="plan-select-label"
                            id="plan-select"
                            value={plan}
                            label="Matrimony Plan"
                            onChange={planChange}
                        >
                            <MenuItem value="90">3 months</MenuItem>
                            <MenuItem value="180">6 months</MenuItem>
                            <MenuItem value="360">12 months</MenuItem>
                        </Select>
                    </FormControl>
                )}

                <Box className="section-new-category-add-news-category-flex" sx={{ fontWeight: 'bold' }}>
                    <div className='overview-header-first-icon'></div>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Updated Date
                    </Typography>
                </Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Updated Date"
                        sx={{ mb: 5, width: '100%' }}
                        value={updatedDate}
                        onChange={(newValue) => { dateChange(newValue) }}
                    />
                </LocalizationProvider>

                <Box className="section-new-category-add-news-category-flex" sx={{ fontWeight: 'bold' }}>
                    <div className='ad-set-first-icon'></div>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Ads Set
                    </Typography>
                </Box>
                <FormControl fullWidth={true}>
                    <InputLabel id="type-select-label">Ads Set</InputLabel>
                    <Select
                        labelId="type-select-label"
                        id="type-select"
                        label="Ads Set"
                        value={allAddSetSelect}
                        onChange={(e) => { handleAddSetSelectChange(e.target.value) }}
                        sx={{
                            '& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select': {
                                height: 'auto',
                                minHeight: '1.4375em',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                flexDirection: 'row',
                                display: 'flex',
                                justifyContent: 'space-between',
                            }
                        }}
                    >
                        <MenuItem value=""><em>None</em></MenuItem>
                        {allAddSets.map((set, index) => (
                            <MenuItem value={set?._id} key={index}>{set?.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <Box className="section-new-category-add-news-content-flex">
                    <div className='new-category-view-icon'></div>
                    <Typography variant="h6" className='new-category-font-bold'>
                        News Content
                    </Typography>
                </Box>
                <Box>
                    <ReactQuill
                        ref={reactQuillRef}
                        value={editorContent}
                        onChange={handleChange}
                        modules={modules}
                        theme="snow"
                    />
                    {spinner &&
                        <Box sx={{ width: '100%' }}>
                            <LinearProgress />
                        </Box>}
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        gap: '10px',
                        padding: '20px'
                    }}
                >
                    <Button
                        variant='contained'
                        style={{
                            backgroundColor: "#2A85FF", color: "white",
                            borderRadius: 2,
                            fontSize: 15,
                            flex: '1 1 auto', // Allow buttons to grow and shrink as needed
                            minWidth: '120px'
                        }}
                        onClick={() => { editNews() }}
                    >Update</Button>
                    {newsStatus === 'draft' && (
                        <Button
                            variant='contained'
                            style={{
                                borderRadius: 2,
                                fontSize: 15,
                                flex: '1 1 auto',
                                minWidth: '120px',
                            }}
                            color='success'
                            onClick={() => { publishNewsDialogOpen() }}
                        >Publish</Button>)}
                </Box>
            </Box>
            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={publishNewsDialog}
                onClose={() => publishNewsDialogClose()}
            >
                <DialogTitle>
                    Are you sure you want to publish this news?
                </DialogTitle>
                <DialogContent>
                    <div style={{ display: "flex", justifyContent: "center", gap: 2, flexDirection: 'wrap' }}>
                        <Button variant="contained" color='primary' sx={{ fontSize: 15 }} onClick={() => { newsPublish(newsId) }}>YES</Button>
                        <Button color='error' sx={{ fontSize: 15 }} onClick={publishNewsDialogClose} >NO</Button>
                    </div>
                </DialogContent>
            </Dialog>
            <Toaster
                position="top-center"
                reverseOrder={false}
                toastOptions={{
                    success: {
                        style: {
                            background: 'rgb(46, 125, 50)',
                            color: 'white'
                        },
                        iconTheme: {
                            primary: 'rgb(46, 125, 50)',
                            secondary: 'white',
                        },

                    },
                    error: {
                        style: {
                            background: 'rgb(211, 47, 47)',
                            color: 'white'
                        },
                        iconTheme: {
                            primary: 'rgb(211, 47, 47)',
                            secondary: 'white',
                        },

                    },
                }}
            />
        </div>
    )
}

export default EditNews;