import React, { useState, useRef, useEffect, useCallback } from 'react'
import { Box, Button, Card, FormControl, InputLabel, LinearProgress, MenuItem, Select, TextField, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import '../../../Styles/newNews.css'
import { getAllAddSet, newsImageUpload, postNews } from '../../../Services/AdminServices';
import toast, { Toaster } from 'react-hot-toast';
import moment from 'moment/moment';
import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';
import 'react-quill/dist/quill.snow.css';
import parse from 'html-react-parser';

const card = styled(Card)(({ theme }) => ({
  backgroundColor: "#fff",
  boxShadow: 'none',
  marginBottom: 20,
  padding: 20,
  width: '70%',
  '@media (max-width: 1200px)': {
    width: '70%', // Adjust width for desktop
  },

  '@media (max-width: 992px)': {
    width: '100%', // Adjust width for tablets
  },
  '@media (max-width: 768px)': {
    width: '100%',// Adjust width for Mobiles
  },
}));

// Image Resize
Quill.register('modules/imageResize', ImageResize);

// Font size in pixel editor
var Size = Quill.import('attributors/style/size');
Size.whitelist = ['8px', '10px', '12px', '14px', '18px', '24px', '36px'];
Quill.register(Size, true);

function NewNews({ categories }) {

  const navigate = useNavigate();

  // Refs for error fields for scroll
  const titleRef = useRef(null);
  const categoryRef = useRef(null);

  // Editor ref
  const reactQuillRef = useRef(null);

  const [category, setCategory] = useState("");
  const [categoryError, setcategoryError] = useState(false);
  const [matrimonyType, setMatrimonyype] = useState("bride");
  const [plan, setPlan] = useState(90);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [editorContent, setEditorContent] = useState('');

  const [spinner, setSpinner] = useState(false);
  const [slug, setSlug] = useState("");

  const [allAddSets, setAllAddSets] = useState([]);
  const [allAddSetSelect, setAllAddSetSelect] = useState("");

  const [publishDisable, setpublishDiable] = useState(false);
  const [draftDisable, setDraftDiable] = useState(false);

  const handleAddSetSelectChange = (value) => {
    setAllAddSetSelect(value);
  }

  const categoryChange = (e) => {
    setCategory(e.target.value);
  }

  const matrimonyTypeChange = (e) => {
    setMatrimonyype(e.target.value);
  }

  const planChange = (e) => {
    let value = Number(e.target.value)
    setPlan(value);
  };

  const handleSlugChange = (event) => {
    let value = event.target.value.replace(/[^\w]/g, '_');
    setSlug(value);
  }

  const handleChange = (value) => {
    setEditorContent(value);
  };

  const imageHandler = useCallback(async () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      if (input.files && input.files[0]) {
        const file = input.files[0];
        const formData = new FormData();
        formData.append('image', file);

        try {
          setSpinner(true);
          // Make an HTTP request to send the file to the backend
          const res = await newsImageUpload(formData);
          const url = res?.data?.file?.url;

          if (url) {
            const quill = reactQuillRef.current.getEditor(); // Access the Quill instance

            if (quill) {
              const range = quill.getSelection(); // Get the current selection
              if (range) {
                quill.insertEmbed(range.index, "image", url); // Insert the image
                // Ensure cursor is placed after the image to come next line
                setTimeout(() => {
                  quill.setSelection(range.index + 1);
                  quill.focus();
                  setSpinner(false);
                }, 0);
              }
            }
          }
        } catch (error) {
          setSpinner(false);
          console.error("Image upload failed:", error);
        }
      }
    };
  }, []);


  const modules = {
    toolbar: {
      container: [
        // [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ 'size': ['8px', '10px', '12px', '14px', '18px', '24px', '36px'] }],
        // [{ font: [] }], // fonts
        // [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['bold', 'italic',
          // 'underline', 'strike'
        ],
        [{ 'align': [] }],
        [
          // 'link', 
          'image', 'video'],
        // ['blockquote', 'code-block'],
        [{ 'color': [] }, { 'background': [] }],
        ["undo", "redo"]
        // ['clean'] // Add a button to clear formatting
      ],
      handlers: {
        image: imageHandler,
      },
    },
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize']
    }
  };

  const getCategories = async () => {
    try {
      await getAllAddSet().then((resposne) => {
        setAllAddSets(resposne?.data?.adSets)
      })
    } catch (err) {
      console.log(err);
    }
  }


  useEffect(() => {
    getCategories();
  }, []);

  const scrollToField = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  // EditorJs Content Description Validation
  const editorErrorValidate = (content) => {
    let arr = [];
    let status = true;

    // Parse HTML and push nodes to an array
    parse(content, {
      replace: (domNode) => {
        arr.push(domNode);
        return null;
      },
    });
    // Find a text node in the parsed content
    const descriptionNode = arr.find((node) => node?.type === "text");
    // Set status based on the presence of a text node
    status = descriptionNode ? false : true;
    return status;
  };

  const saveNews = async (status) => {
    try {

      let error = false;

      if (title === "") {
        setTitleError(true);
        scrollToField(titleRef);
        error = true;
      } else {
        setTitleError(false);
      }

      if (category === "") {
        setcategoryError(true);
        scrollToField(categoryRef);
        error = true;
      } else {
        setcategoryError(false);
      }

      if (editorErrorValidate(editorContent)) {
        toast.error("Please enter the description.")
        error = true;
      }

      if (error) return;

      let categor = categories.find(f => f?._id === category);

      let newSlug = "";
      let today = new Date();
      if (slug === "") {
        let name = title.replace(/[^\w\u0D00-\u0D7F]/g, '_');
        newSlug = name + "_" + moment(today).format('MMMM_DD_YYYY');
      } else {
        newSlug = slug + "_" + moment(today).format('MMMM_DD_YYYY');
      }

      let data = {
        category: categor?._id,
        categoryName: categor?.categoryName,
        type: categor?.type,
        title: title,
        content: editorContent,
        slug: newSlug,
        status: status,
        adSet: allAddSetSelect,
      }

      if (categor?.categoryName === "Matrimony") {
        data.matrimonytype = matrimonyType;
        data.planDuration = plan;
      }

      if (status === 'published') {
        setpublishDiable(true);
      }
      if (status === 'draft') {
        setDraftDiable(true);
      }
      await postNews(data).then((res) => {
        toast.success(res?.data?.message);
        setTimeout(() => {
          if (status === 'draft') {
            navigate('/admin/drafted-news');
          }
          if (status === 'published') {
            navigate('/admin/published-news');
          }
        }, 2000);
      })

    } catch (err) {
      setpublishDiable(false);
      setDraftDiable(false);
      toast.error(err.response.data.message)
    }
  };

  const checkingMatrimonyStatus = (categoryId) => {
    let status = false;
    let category = categories && categories.find(cat => cat?._id === categoryId);
    if (category) {
      if (category?.categoryName === "Matrimony") {
        status = true;
      }
    }
    return status;
  }

  return (
    <div>
      <Box>
        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
          Add News
        </Typography>
      </Box>
      <Box component={card}>

        <Box className="section-new-category-add-news-category-flex" sx={{ fontWeight: 'bold' }}>
          <div className='new-title-add-information-icon'></div>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Title
          </Typography>
        </Box>
        <TextField
          ref={titleRef}
          error={titleError}
          id="outlined-basic"
          label="Title"
          placeholder='Title'
          variant="outlined"
          sx={{ mb: 5 }}
          fullWidth={true}
          required={true}
          value={title}
          onChange={(e) => { setTitle(e.target.value) }} />

        <Box className="section-new-category-add-news-category-flex">
          <div className='new-category-add-information-icon'></div>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            News Category
          </Typography>
        </Box>
        <FormControl fullWidth sx={{ mb: 5 }} required={true} error={categoryError} ref={categoryRef}>
          <InputLabel id="demo-simple-select-label">Category</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={category}
            label="Category"
            onChange={(e) => { categoryChange(e) }}
          >
            {categories && categories.map((cat, i) => (
              <MenuItem key={i} value={cat?._id}>{cat?.categoryName}</MenuItem>
            ))}
          </Select>
        </FormControl>
        {checkingMatrimonyStatus(category) && (
          <Box className="section-new-category-add-news-category-flex">
            <div className='new-category-add-information-icon'></div>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Matrimony Type
            </Typography>
          </Box>
        )}
        {checkingMatrimonyStatus(category) && (
          <FormControl fullWidth sx={{ mb: 5 }} required={true}>
            <InputLabel id="demo-simple-select-label">Matrimony Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={matrimonyType}
              label="Matrimony Type"
              onChange={(e) => { matrimonyTypeChange(e) }}
            >
              <MenuItem value={"bride"}>Bride</MenuItem>
              <MenuItem value={"groom"}>Groom</MenuItem>
            </Select>
          </FormControl>
        )}
        {checkingMatrimonyStatus(category) && (
          <Box className="section-new-category-add-news-category-flex">
            <div className='new-category-add-information-icon'></div>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Matrimony Plan
            </Typography>
          </Box>
        )}
        {checkingMatrimonyStatus(category) && (
          <FormControl fullWidth sx={{ mb: 5 }} required={true}>
            <InputLabel id="plan-select-label">Matrimony Plan</InputLabel>
            <Select
              labelId="plan-select-label"
              id="plan-select"
              value={plan}
              label="Matrimony Plan"
              onChange={planChange}
            >
              <MenuItem value="90">3 months</MenuItem>
              <MenuItem value="180">6 months</MenuItem>
              <MenuItem value="360">12 months</MenuItem>
            </Select>
          </FormControl>
        )}
        <Box className="section-new-category-add-news-category-flex">
          <div className='overview-header-first-icon'></div>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Slug(If you leave it blank, it will be generated automatically.)
          </Typography>
        </Box>
        <TextField
          id="outlined-basic"
          label="Slug"
          variant="outlined"
          sx={{ mb: 5 }}
          fullWidth={true}
          value={slug}
          onChange={(e) => { handleSlugChange(e) }} />

        <Box className="section-new-category-add-news-category-flex" sx={{ fontWeight: 'bold' }}>
          <div className='ad-set-first-icon'></div>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Ads Set
          </Typography>
        </Box>
        <FormControl fullWidth={true}>
          <InputLabel id="type-select-label">Ads Set</InputLabel>
          <Select
            labelId="type-select-label"
            id="type-select"
            label="Ads Set"
            value={allAddSetSelect}
            onChange={(e) => { handleAddSetSelectChange(e.target.value) }}
            sx={{
              '& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select': {
                height: 'auto',
                minHeight: '1.4375em',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'space-between',
              }
            }}
          >
            <MenuItem value=""><em>None</em></MenuItem>
            {allAddSets.map((set, index) => (
              <MenuItem value={set?._id} key={index}>{set?.name}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box className="section-new-category-add-news-content-flex">
          <div className='new-category-view-icon'></div>
          <Typography variant="h6" className='new-category-font-bold'>
            News Content
          </Typography>
        </Box>
        <Box>
          <ReactQuill
            ref={reactQuillRef}
            value={editorContent}
            onChange={handleChange}
            modules={modules}
            theme="snow"
          />
          {spinner &&
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>}
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            gap: '10px',
            padding: '20px'
          }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#2A85FF",
              color: "white",
              borderRadius: 2,
              fontSize: 15,
              flex: '1 1 auto', // Allow buttons to grow and shrink as needed
              minWidth: '120px', // Ensure a minimum width for smaller screens
            }}
            disabled={draftDisable}
            onClick={() => saveNews('draft')}
          >
            Save to Draft
          </Button>
          <Button
            variant="contained"
            color="success"
            sx={{
              borderRadius: 2,
              fontSize: 15,
              flex: '1 1 auto',
              minWidth: '120px',
            }}
            disabled={publishDisable}
            onClick={() => saveNews('published')}
          >
            Publish News
          </Button>
        </Box>

      </Box>

      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          success: {
            style: {
              background: 'rgb(46, 125, 50)',
              color: 'white'
            },
            iconTheme: {
              primary: 'rgb(46, 125, 50)',
              secondary: 'white',
            },

          },
          error: {
            style: {
              background: 'rgb(211, 47, 47)',
              color: 'white'
            },
            iconTheme: {
              primary: 'rgb(211, 47, 47)',
              secondary: 'white',
            },

          },
        }}
      />
    </div>
  )
}

export default NewNews;