import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import { FacebookIcon, FacebookShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { saveToLocalStorage } from '../../../Helper/helper';
import parse from 'html-react-parser';

const Item = styled(Card)(({ theme }) => ({
    backgroundColor: "#fff",
    boxShadow: 'none',
    marginBottom: 20
}));

function NewsDetail({ news }) {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const status = searchParams.get('status');
    const [metaData, setMetaData] = useState(null);

    // Function to extract the first image from the HTML content
    const extractFirstImage = (htmlContent) => {
        let arr = [];
        parse(htmlContent, {
            replace: (domNode) => {
                arr.push(domNode);
                return null;
            },
        });

        // Find the first image node
        const imageNode = arr.find((node) => node?.name === 'img');
        return imageNode ? imageNode.attribs.src : 'logo.svg'; // Fallback if no image found
    };

    // Handle dynamic meta tags
    useEffect(() => {
        if (news) {
            const imageUrl = extractFirstImage(news?.content);
            setMetaData({
                title: news?.title,
                description: news?.description || 'Default description',
                imageUrl: imageUrl,
                url: `${window.location.origin}/share?news=${news?.slug}`
            });
        }
    }, [news]);

    const createFormattedSlug = (slug) => {
        return slug
            .trim()
            .replace(/\s+/g, '%20') // Replace spaces with hyphens
    }

    // If the meta data is not set, show a loading state
    if (!metaData) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <Item>
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div style={{ padding: 20 }}>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                gap: '20px',
                                alignItems: 'center',
                                flexWrap: 'wrap'
                            }}>
                                <span className='news-details-category-card-green'>
                                    {news?.categoryName}
                                </span>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '20px',
                                    alignItems: 'center',
                                    flexWrap: 'wrap'
                                }}>
                                    <WhatsappShareButton
                                        url={`${window.location.origin}/share?news=${createFormattedSlug(news?.slug)}`}
                                        title={`${news?.title}`}
                                        htmlTitle={`${news?.title}`}
                                    >
                                        <WhatsappIcon size={32} round />
                                    </WhatsappShareButton>

                                    <FacebookShareButton
                                        url={`${window.location.origin}/share?news=${createFormattedSlug(news?.slug)}`}
                                        title={`${news?.title}`}
                                        htmlTitle={`${news?.title}`}
                                    >
                                        <FacebookIcon size={32} round />

                                    </FacebookShareButton>


                                    {status && (
                                        <Button sx={{ fontWeight: 'bold' }} onClick={() => { saveToLocalStorage(window.location.pathname, window.scrollY); navigate(`/admin/edit-news/${news?.slug}`) }} size='small' variant="contained" disableElevation startIcon={<EditIcon />}>
                                            Edit
                                        </Button>
                                    )}
                                </div>
                            </div>
                            <Card style={{ boxShadow: 'none', backgroundColor: '#F1F1F1', padding: "10px", margin: '20px 0px 20px 0px' }}>
                                <span className='worldFlex'>
                                    <span className='worldChildFlex'>
                                        {news?.categoryName !== "Matrimony" && (
                                            <span>
                                                <VisibilityOutlinedIcon sx={{ height: '20px', width: '20px' }} />
                                            </span>
                                        )}
                                        {news?.categoryName !== "Matrimony" && (
                                            <span>
                                                <p className='date-time-text-style'>
                                                    {news?.viewsCount}
                                                </p>
                                            </span>
                                        )}
                                    </span>
                                    {!['Buy & Sell', 'Rentals', 'Real Estate', 'Vehicles'].includes(news?.categoryName) && (
                                        <span className='worldChildFlex'>
                                            <span>
                                                <p className='date-time-text-style'>
                                                    {moment(news?.date).format('DD-MM-YYYY')}
                                                </p>
                                            </span>
                                        </span>
                                    )}
                                </span>
                            </Card>
                            <Box>
                                <div className='news-details-title'>
                                    {news?.title}
                                </div>
                                <div>
                                    <div className="custom-html-content-news-Details" dangerouslySetInnerHTML={{ __html: news?.content }}></div>
                                </div>
                            </Box>
                        </div>
                    </Grid>
                </Grid>
            </Item>
        </>
    );
}

export default NewsDetail;